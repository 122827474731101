import React, { useState, useEffect } from "react";
import css from "../css/tournament.module.css";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";

const Tournament = () => {
  const [activeTab, setActiveTab] = useState("winnerPrizes");
  const [activeTab1, setActiveTab1] = useState("joinerlist");
  const [tournamentData, setTournamentData] = useState([]);
  const [winnerPrizes, setWinnerPrizes] = useState({});
  const [winnerPrizesFirst, setWinnerPrizesFrist] = useState(0);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [enteyFee, setEnteyFee] = useState({});
  const [tournamentIds, setTournamentId] = useState(null);
  const [tourmentJoinLenght, settourmentLenght] = useState(0);
  const [pairJoin, setPairJoin] = useState([]);
  const [userId, setUserId] = useState(null);
  const [pairedlistID, setpairedlistID] = useState(null);
  const [playerid, setPlayerid] = useState([]);
  const [isJoining, setIsJoining] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // State to track current page
  const tournamentsPerPage = 5; // Number of tournaments per page
  const totalTournaments = tournamentData.length;
  const totalPages = Math.ceil(totalTournaments / tournamentsPerPage);

  // Calculate the index of the first and last tournament to display
  const indexOfLastTournament = currentPage * tournamentsPerPage;
  const indexOfFirstTournament = indexOfLastTournament - tournamentsPerPage;
  const currentTournaments = tournamentData.slice(indexOfFirstTournament, indexOfLastTournament);

  // Change page handler
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const baseUrl = process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_LOCAL_API
    : process.env.REACT_APP_BACKEND_LIVE_API;

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserId(res.data._id)
      })
      .catch((e) => {
        console.log("Error");
      });
  };


  useEffect(() => {
    role();
  })


  useEffect(() => {

    fetchTournamentData();
  }, []);

  const fetchTournamentData = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };
      const response = await axios.get(`${baseUrl}tournament-list`, { headers });

      const tournaments = response.data.TournamentModeldata || [];
      console.warn(tournaments);

      if (tournaments.length > 0) {
        setEnteyFee(tournaments[0].entry_fees);
        setTournamentId(tournaments[0].t_id);
        setWinnerPrizes(response.data.TournamentWinnerPrizedata[0]);
        setWinnerPrizesFrist(response.data.TournamentWinnerPrizedata[0].winnerPrize1);
        fetchLeaderboardData(tournaments[0].t_id);
        setTournamentData(tournaments);
      } else {
        console.warn("No tournaments available.");
      }
    } catch (error) {
      console.error("Error fetching tournament data:", error);
    }
  };

  const TournamentPairJoin = async () => {
    if (!tournamentIds) {
      console.warn("Tournament ID is not set. Skipping TournamentPairJoin.");
      return;
    }

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await axios.post(
        `${baseUrl}tournament-pair-and-join-list`,
        { tournament_id: tournamentIds },
        { headers }
      );

      const currentUserId = localStorage.getItem("idss");

      const hasJoined = res.data.Joinlist.some(
        (joiner) => joiner.userid === currentUserId
      );

      setPairJoin(res.data.pairedlist);
      console.warn(hasJoined);
      setIsJoining(hasJoined);

    } catch (e) {
      console.error("Error in TournamentPairJoin:", e);
    }
  };


  useEffect(() => {
    TournamentPairJoin();
  }, [tournamentIds]);

  const handleJoinNow = (tournamentId) => {
    console.log(`Joining tournament: ${tournamentId}`);
    axios
      .post(
        `${baseUrl}tournament-join`,
        { tournament_id: tournamentId, Game_type: "tournament", Game_Ammount: enteyFee },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      )
      .then((res) => {
        Swal.fire({
          title: res.data.msg,
          icon: res.data.status ? "success" : "warning",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to join the tournament.");
      });

  };

  const fetchLeaderboardData = async (tournamentId) => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${access_token}` };

      const response = await axios.post(
        `${baseUrl}tournament-join-player-list`,
        { tournament_id: tournamentId },
        { headers }
      );
      settourmentLenght(response.data.TournamentModeldata.length);
      setLeaderboardData(response.data.TournamentModeldata || []);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    }
  };

  const isUserPaired = pairJoin.some(
    (pair) => pair.creater_userid === userId || pair.joiner_userid === userId
  );

  useEffect(() => {
    const userPair = pairJoin.find(
      (pair) => pair.creater_userid === userId || pair.joiner_userid === userId
    );

    if (userPair && userPair._id !== playerid) {
      setPlayerid(userPair._id);
    }
  }, [userId, pairJoin, playerid]);

  return (
    <div className="leftContainer" style={{ minHeight: "100vh" }}>
      <div
        className={css.mainArea}
        style={{ paddingTop: "60px", minHeight: "100vh" }}
      >
        <div className={css.tournamentContainer}>
        {tournamentData.length > 0 ? (
          tournamentData.map((tournament, index) => (
            <>
              <div key={index} className={css.tournament}>

                <div className="bg-white rounded">
                  <div className='winner_tab'>
                    <button
                      className={`${css.tabButton} ${activeTab === "winnerPrizes" ? css.activeTab : ""}`}
                      onClick={() => setActiveTab("winnerPrizes")}
                    >
                      Details
                    </button>
                    <button
                      className={`${css.tabButton} ${activeTab === "leaderboard" ? css.activeTab : ""}`}
                      onClick={() => {
                        setActiveTab("leaderboard");
                        
                      }}
                    >
                      Leaderboard
                    </button>
                  </div>
                  {activeTab === "winnerPrizes" && (
                    <div className="">
                      <div className="w-100 mt-2">
                        <div className="detail">
                          <p>
                            <i className="fa fa-heart"></i>
                            You have {tournament.total_user} out of {tournament.total_user - tourmentJoinLenght} entries left</p>
                        </div>

                        <div className="prize-pool-content mx-3">
                          <div className="tournament-entries">
                            <div className="heading">Tournament Entries
                              <div>{tournament.date}</div>
                            </div>
                            <div className="progress-bar" style={{ backgroundColor: '#e0e0e0' }}>
                              <div
                                className="progress"
                                style={{
                                  width: `${(tourmentJoinLenght / tournament.total_user) * 100}%`,
                                  backgroundColor: '#4caf50'
                                }}
                              ></div>
                            </div>
                            <div className="fill-amount">
                              {tourmentJoinLenght}/{tournament.total_user} Filled
                            </div>
                          </div>


                          <ul className="tournament-statistics">
                            <li>
                              <i className="fa fa-ticket"></i>
                              <div className="entry">
                                <div className="title">Fee Per Entry</div>
                                <div className="amount">₹{enteyFee}</div>
                              </div>
                            </li>
                            <li>
                              <i className="fa fa-award"></i>
                              <div className="entry">
                                <div className="title">First Prize</div>
                                <div className="amount">₹{winnerPrizesFirst}</div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="my-3 mx-3 distribution">
                          <div class="prize_title">Prize Distribution
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="contentboard">
                              Rank
                            </div>
                            <div className="contentboard">Winning</div>
                          </div>
                          {Object.entries(winnerPrizes).map(([key, value], idx) =>
                            key.startsWith("winnerPrize") ? (
                              <div key={idx} className="d-flex justify-content-between pricerank">
                                <div className="bgimage_new prize text-dark ">
                                  {key.replace("winnerPrize", "")}
                                </div>
                                <div className="prize text-dark  padding_15 text-right">
                                  <span>₹{value}</span>
                                </div>
                              </div>
                            ) : null
                          )}
                        </div>

                        <div className="game-rule-faq mx-3 mb-3">
                          <div className="title">
                            Game Rules & FAQ
                            {/* <button type="button" className="btn-language-change btn btn-primary">
                              हिंदी
                            </button> */}
                          </div>
                          <div className="faq-section">
                            <div className="accordion" id="gameRulesFaqAccordion">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    General Game Format Rules
                                  </button>
                                </h2>
                                <div
                                  id="collapseOne"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#gameRulesFaqAccordion"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <span className="bold">Total Moves:</span> Each player has 36 moves to complete the game.
                                    </p>
                                    <p>
                                      <span className="bold">Time Limit per Move:</span> Players have 15 seconds to make each move. If a player misses a move 6 times, they will be exited from the game, and their current score will be final.
                                    </p>
                                    <p>
                                      <span className="bold d-block">Scoring:</span>
                                    </p>
                                    <ul>
                                      <li>
                                        Moving one box earns <span className="bold">1 point.</span>
                                      </li>
                                      <li>
                                        Get <span className="bold">10 Extra score</span> when your token reaches the final position.
                                      </li>
                                      <li>Players can tap on tokens to see the points each token carries.</li>
                                    </ul>
                                    <p>
                                      <span className="bold">Extra Moves:</span> Players get extra moves when,
                                    </p>
                                    <ul>
                                      <li>They kill a token placed in a fixed position on the board.</li>
                                      <li>A token reaches the Home position.</li>
                                    </ul>
                                    <p>
                                      <span className="bold">Token Cutting:</span> Each game has 36 open moves, with{" "}
                                      <span className="bold">8-10 tokens</span> available to cut for extra points.
                                    </p>
                                    <p>
                                      <span className="bold">Board Setup: </span>All players have the same board layout, with tokens placed in the same starting positions.
                                    </p>
                                    <p>
                                      <span className="bold">Dice Rolls:</span>
                                    </p>
                                    <ul>
                                      <li>Every player receives an equal number of each dice value (1, 2, 3, 4, 5, and 6).</li>
                                      <li>The dice values are arranged in random order, so players get a different sequence of rolls.</li>
                                    </ul>
                                    <p>
                                      <span className="bold">Objective:</span> Score the highest points by cutting tokens and reaching Home quickly to rank at the top of the leaderboard.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    Game Tie: Prize Distribution [Important]
                                  </button>
                                </h2>
                                <div
                                  id="collapseTwo"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#gameRulesFaqAccordion"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <span className="bold">Equal Ranks:</span> If two or more players achieve the same score, they are
                                      considered to have the same rank. The prize pool for their ranks will be combined and distributed
                                      equally among them.
                                    </p>
                                    <p>
                                      <span className="bold d-block">1. Tied 1st Place:</span>
                                    </p>
                                    <p>
                                      If two players tie for 1st, they split the prize for 1st and 2nd place equally. The next player gets
                                      the 3rd place prize.
                                    </p>
                                    <p>Example:</p>
                                    <ul>
                                      <li>1st place prize: ₹1000</li>
                                      <li>2nd place prize: ₹500</li>
                                      <li>
                                        If two players tie for 1st, they split ₹1500 (₹1000 + ₹500), so each gets ₹750.
                                      </li>
                                      <li>The next player receives ₹300 for 3rd place.</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    Frequently Asked Questions
                                  </button>
                                </h2>
                                <div
                                  id="collapseThree"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#gameRulesFaqAccordion"
                                >
                                  <div className="accordion-body">
                                    <p>
                                      <span className="bold d-block">What color tokens will I play with?</span>
                                      You'll always be assigned random color tokens. All players in the game will play with random color
                                      tokens.
                                    </p>
                                    <p>
                                      <span className="bold d-block">What happens if I cut a token?</span>
                                      When you cut another token, you earn points equal to the number of moves that token is away from its
                                      Home position. For example, if the token is 10 moves ahead of its Home position, you’ll receive 10
                                      points.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  )}

                  {activeTab === "leaderboard" && (
                    <div className="mt-2">

                      <div className='winner_tab'>
                        <button
                          className={`${css.tabButton} ${activeTab1 === "joinerlist" ? css.activeTab : ""}`}
                          onClick={() => {setActiveTab1("joinerlist");}}>
                          Joiner list
                        </button>
                        <button className={`${css.tabButton} ${activeTab1 === "pairlist" ? css.activeTab : ""}`}
                          onClick={() => setActiveTab1("pairlist")}>
                          Pair list
                        </button>
                      </div>

                      {activeTab1 === "joinerlist" && (
                        <>
                          <div className="joiner_table d-flex justify-content-center leaderboard leaderboardesign mt-2">
                            <div className="table-responsive">
                              <table className="table text-center">
                                <thead>
                                  <tr>
                                    <th>User Name</th>
                                    <th>Score</th>
                                    <th>Level</th>
                                    <th>Results</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {leaderboardData.length > 0 ? (
                                    leaderboardData
                                      .sort((a, b) => b.point - a.point) 
                                      .map((entry, idx) => (
                                        <tr key={idx}>
                                          <td>
                                            <picture>

                                              {entry && entry.profile ? (
                                                <img
                                                  className="mr-1"
                                                  src={`https://kycjvt.rushkingludo.com/${entry && entry.profile}`}
                                                  alt="avtar"

                                                />
                                              ) : (
                                                <img
                                                  className="mr-1"
                                                  src={process.env.PUBLIC_URL + `Images/user.png`}
                                                  alt="user"

                                                />
                                              )}
                                            </picture>
                                            {entry.username}</td>
                                          <td>{entry.point}</td>
                                          <td>{entry.lavel}</td>
                                          <td
                                            style={{
                                              color:
                                                entry.result === "awaiting"
                                                  ? "orange"
                                                  : entry.result === "win" || entry.result === "winn"
                                                    ? "green"
                                                    : "red",
                                            }}
                                          >
                                            {entry.result}
                                          </td>
                                        </tr>
                                      ))
                                  ) : (
                                    <tr>
                                      <td colSpan="3">No leaderboard data available.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="col-12 text-center mt-3">
                            <div className="pagination-controls d-flex justify-content-center">
                              <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="pagination-button"
                              >
                                <RiArrowLeftSLine />
                              </button>
                              {[...Array(totalPages)].map((_, idx) => (
                                <button
                                  key={idx}
                                  onClick={() => paginate(idx + 1)}
                                  className={`pagination-button ${currentPage === idx + 1 ? "active" : ""}`}
                                >
                                  {idx + 1}
                                </button>
                              ))}
                              <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="pagination-button"
                              >
                                <RiArrowRightSLine />
                              </button>
                            </div>
                          </div>
                        </>

                      )}

                      {activeTab1 === "pairlist" && (
                        <>
                          <div className="joiner_table bg-light rounded creator_table table-responsive mt-2">
                            <table className="table">
                              <>
                                <tr>
                                  <th>#</th>
                                  <th className="d-flex justify-content-center">Player 1</th>
                                  <th></th>
                                  <th className="d-flex justify-content-center">Player 2</th>
                                </tr>
                              </>
                              <>
                                {pairJoin && pairJoin.map((game, key) => (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      <span className="pl-2">


                                        {game && game.CreaterProfile ? (
                                          <img
                                            className="mr-1"
                                            src={`https://kycjvt.rushkingludo.com/${game && game.CreaterProfile}`}
                                            alt="avtar"
                                          />
                                        ) : (
                                          <img
                                            className="mr-1"
                                            src={process.env.PUBLIC_URL + `Images/user.png`}
                                            alt="user"

                                          />
                                        )}
                                        {game.CreaterUserName}</span>
                                    </td>
                                    <td> <img
                                      src={
                                        process.env.PUBLIC_URL + "/Images/Homepage/versus.png"
                                      }
                                      alt=""
                                      width="21px"
                                    /></td>
                                    <td>
                                      <span className="pl-2">


                                        {game && game.JoinerProfile ? (
                                          <img
                                            src={`https://kycjvt.rushkingludo.com/${game && game.JoinerProfile}`}
                                            alt="avtar"
                                            className="mr-1"
                                          />
                                        ) : (
                                          <img
                                            src={process.env.PUBLIC_URL + `Images/user.png`}
                                            alt="user"
                                            className="mr-1"
                                          />
                                        )}
                                        {game.JoinerUserName}</span>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            </table>
                            <div className="pagination-controls mb-2">
                              <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                <RiArrowLeftSLine />
                              </button>
                              {[...Array(totalPages)].map((_, idx) => (
                                <button
                                  key={idx}
                                  onClick={() => paginate(idx + 1)}
                                  className={currentPage === idx + 1 ? 'active' : ''}
                                >
                                  {idx + 1}
                                </button>
                              ))}
                              <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                              >
                                <RiArrowRightSLine />
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                  )}
                </div>
              </div>

              <div className="fixclassprize">

                {isUserPaired ? (
                  <Link
                    to={{ pathname: `/tourmentresult/${playerid}` }}
                    style={{ bottom: "0" }}
                  >
                    <button
                      className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}
                    >
                      Play
                    </button>
                  </Link>
                ) : isJoining ? (
                  <div className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}>
                    You have joined
                  </div>
                ) : (
                  <button
                    className={`d-flex w-100 mt-2 text-center justify-content-center ${css.joinNowButton}`}
                    onClick={() => {
                      setIsJoining(true);
                      handleJoinNow(tournament.t_id);
                    }}
                  >
                    Join Now ₹{tournament.entry_fees}
                  </button>
                )}
              </div>

            </>

          ))
        ) : (
          <picture className="gameCard-image rounded-lg">
          <img
            width="100%"
            src={
              process.env.PUBLIC_URL + "/Images/Homepage/Tournament-Poster.png"
            }
            
            alt=""
          />
        </picture>
        )}
        </div>

      </div>
    </div>
  );
};

export default Tournament;
